<template>
  <v-dialog
    :value="isShowDialog"
    max-width="300"
    @keydown.esc="close"
    @click:outside="close"
  >
    <v-card>
      <v-card-title class="display-2">
        {{ title }}
        <v-spacer />

        <v-icon
          aria-label="Close"
          @click="close"
        >
          mdi-close
        </v-icon>
      </v-card-title>

      <v-card-text class="pb-6 pt-12 text-center">
        {{ body }}
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn
          color="success"
          text
          @click="action"
        >
          {{ this.$t('button.yes') }}
        </v-btn>
        <v-btn
          class="mr-3"
          color="error"
          text
          @click="close"
        >
          {{ this.$t('button.no') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: 'ConfirmationDialog',
    props: {
      title: {
        required: false,
        type: String,
        default: '',
      },
      body: {
        required: true,
        type: String,
        default: '',
      },
      action: {
        required: true,
        type: Function,
      },
      showDialog: {
        required: true,
        type: Boolean,
        default: false,
      },
      storeFunctionName: {
        required: true,
        type: String,
      },
    },
    computed: {
      isShowDialog () {
        return this.showDialog
      },
    },
    methods: {
      close: function () {
        this.$emit('update:showDialog', false)
      },
    },
  }
</script>

<style scoped>

</style>
